import BaseLayout from 'layouts/baseLayout/baseLayout';
import { useEffect, useState } from 'react';
import { DatePicker, Select, notification } from 'antd';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';
import
{
  SearchOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import AdminConfig from './adminConfig';
import Table from './Table';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { makeRequest } from 'features/communication/request';
import ManualSubmission from './ManualSubmission';



dayjs.extend(customParseFormat);

const AdminConsole = () =>
{
  const { state, isOpen } = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const { current_date, from_date } = AdminConfig();
  const [data, setCurrentItems] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [clientName, setClientName] = useState();
  const [statuses, setStatus] = useState([])
  const [fromDate, setFromDate] = useState(moment(`${from_date}T00:00:00`));
  const [toDate, setToDate] = useState(moment(`${current_date}T23:59:59`));
  const [selectedStatus, setSelectedStatus] = useState('All')
  const [loading, setIsLoading] = useState(false)
  const [error, setError] = useState({ error: '', isError: false })
  const [isSubmissionDone, setIsSubmissionDone] = useState(false)


  const isFormValid = () =>
  {
    if (fromDate > toDate._i || fromDate._i > toDate || fromDate._i > toDate._i || fromDate > toDate)
    {
      setError({ error: 'Please select From Date less than To Date', isError: true })
      return false
    }
    return true
  }

  useEffect(() =>
  {
    const customersInfo= [
      { name: 'TPE', id: 'tpe' },
      { name: 'TST', id: 'tst' },
      { name: 'Apple', id: 'apple' },
      { name: 'ERGO', id: 'ergo' },
      { name: 'Pfizer', id: 'pfizer' },
      { name: 'NNGroup', id: 'nngroup' },
      { name: 'Milliken', id: 'milliken' },
      { name: 'GKN', id: 'gkn' }
    ]
    setCustomers(customersInfo);
    setClientName(customersInfo[0]['name']);
    getAdminData(customersInfo[0]['name']);
    // API.getClients().then((response) =>
    // {
    //   setCustomers(response.customers);
    //   setClientName(response.customers[0]['name']);
    //   getAdminData(response.customers[0]['name']);
    // });
  }, []);

  const getTripstatus = async () =>
  {
    try
    {
      await makeRequest({
        url: 'api/v3/trips/statusfilters',
        method: 'options',
      }).then((res) =>
      {
        setStatus(res.data)
      }).catch((error) =>
      {
        return error
      })
    } catch (error)
    {
      return error
    }
  }

  useEffect(() =>
    {
      getTripstatus()
    }, [])
  
  useEffect(() =>
  {
    sessionStorage.setItem('clientName', clientName)
  }, [clientName])

  async function getAdminData(param) {
    if (isFormValid()) {
      setIsLoading(true);
      const startDate = typeof fromDate === 'object' ? `${fromDate._i.replace(' ', '')}` : `${fromDate.replace(' ', '')}:00:00`;
      const endDate = typeof toDate === 'object' ? `${toDate._i.replace(' ', '')}` : `${toDate.replace(' ', '')}:59:59`;
      const status = selectedStatus == "All" ? "" : selectedStatus;
  
      try {
        const mail = sessionStorage.getItem('mail'); // Retrieve mail from sessionStorage
        await makeRequest({
          url: `api/v3/Trips/Status?FromDate=${startDate}&ToDate=${endDate}&status=${status}`,
          method: 'get',
          headers: {
            client_code: param ? param : clientName,
            user: mail, // Add mail to headers
          },
        }).then((res) => {
          if(res.status ===204)
          {
            setCurrentItems([]);
            api.info({
              message: 'No Records Available',
              description: '',
              placement: 'topRight',
            });
          }
          else{
            setCurrentItems(res.data);            
          }
          setIsLoading(false);
        }).catch((error) => {
          api.info({
            message: 'error',
            description: error.description,
            placement: 'topRight',
          });
          setIsLoading(false);
        });
      } catch (error) {
        api.info({
          message: 'error',
          description: error.description,
          placement: 'topRight',
        });
        setIsLoading(false);
      }
    }
  }
  const exportToCSV = async () =>
  {
    const startDate= typeof fromDate === 'object' ? `${fromDate._i.replace(' ', '')}` : `${fromDate.replace(' ', '')}:00:00`;
    const endDate = typeof toDate === 'object' ? `${toDate._i.replace(' ', '')}` : `${toDate.replace(' ', '')}:59:59`;
    const status= selectedStatus == "All" ? "" : selectedStatus;
    try
    {
      const mail = sessionStorage.getItem('mail'); // Retrieve mail from sessionStorage
      await makeRequest({
        url: `api/v3/Trips/Export?startDate=${startDate}&endDate=${endDate}&status=${status}`,
        method: 'download',
        headers: {
          client_code: clientName,
          user: mail, // Add mail to headers          
        },
      }).then((res) =>
      {
        if(res.status === 204)
        {
          api.info({
            message: 'Download Failed',
            description: 'No Records Available',
            placement: 'topRight',
          });
        }
        else
        {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `MytripsPlus_Report.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }).catch((error) =>
      {
        api.info({
          message: 'error',
          description: error.description,
          placement: 'topRight',
        });

      })
    } catch (error)
    {
      api.info({
        message: 'error',
        description: error.description,
        placement: 'topRight',
      });
    }
  }

  const onChange = (date, dateString) =>
  {
    setFromDate(dateString);
    setError({ error: '', isError: false })
  };

  const onSetTodate = (date, dateString) =>
  {
    setToDate(dateString);
    setError({ error: '', isError: false })
  };

  // Render the UI for your table
  return (
    <>
      {contextHolder}
      <BaseLayout>
        <div className="container-fluid container_MT">
          <div className="admin_action_flex">
            <div className="width">
              <div className="flex">
                <div className="margin_right">
                  <h6>From Date</h6>

                  <DatePicker
                    showTime={{ defaultValue: dayjs('00:00:00', 'THH') }}
                    format="YYYY-MM-DD THH"
                    className="width_input"
                    defaultValue={moment(fromDate)}
                    onChange={onChange}
                  />
                </div>
                <div className="margin_right">
                  <h6>To Date</h6>
                  <DatePicker
                    showTime={{ defaultValue: dayjs('00', 'THH') }}
                    format="YYYY-MM-DD THH"
                    className="width_input"
                    defaultValue={moment(toDate)}
                    onChange={onSetTodate}
                  />
                </div>

                <div className="select">
                  <h6>Client Name</h6>
                  <Select
                    className="width"
                    value={clientName}
                    placeholder="Please select"
                    onChange={setClientName}
                  >
                    {customers &&
                      customers.map(({ id, name }) =>
                      {
                        return (
                          <Select.Option key={id} value={name}>
                            {name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>

                <div className="select_filter" style={{ marginLeft: '16px', marginRight: '16px' }}>
                  <h6>Filter Criteria</h6>
                  <Select
                    className="width_status"
                    value={selectedStatus}
                    placeholder="Please select"
                    style={{ width: '116px !important' }}
                    onChange={setSelectedStatus}
                  >
                    {statuses &&
                      statuses.map((value) =>
                      {
                        return (
                          <Select.Option key={value} value={value}>
                            {value}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="button_container">
                  <button className='admin_searchicon_button' onClick={() => getAdminData('')}>
                    <SearchOutlined style={{ fontSize: '24px' }} />
                  </button>
                  <button className='admin_searchicon_button export_csv_btn' onClick={exportToCSV}>
                    <DownloadOutlined style={{ fontSize: '24px' }} />
                  </button>
                </div>
                <div>

                </div>
              </div>
              <div style={{ color: 'red' }}>{error && error.isError && error.error}</div>
            </div>
          </div>
          <Table data={data} loading={loading} />
          <ManualSubmission setIsSubmissionDone={() => getAdminData(sessionStorage.getItem('clientName'))} />
        </div>
      </BaseLayout>
    </>
  );
}

export default AdminConsole;