import React, { Component } from 'react';
import BaseLayout from '../layouts/baseLayout/baseLayout';
import axios from 'axios'; // Import axios

class MainPage extends Component {
  state = {
    windowOpened: false,
    email: '',           // Email address from sessionStorage
    tripsData: [],       // Data received from the GET API
    loading: true,       // Loading state for the API call
    error: null,         // Error state in case the API call fails
  };

  // Method to fetch data from the API using axios
  fetchTrips = async (email) => {
    try {
      const token = 'Bearer ' + sessionStorage.getItem('token');
      const mail = sessionStorage.getItem('mail'); // Retrieve mail from sessionStorage
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}api/v3/trip/questionnaire/forms`, {
        params: { EmailAddress: email, Status: 'OnlineForm' }, // Pass email as a query parameter
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
          "Upgrade-Insecure-Requests": 1,
          'user': mail, // Add mail to headers
        },
      });
      // Update state with the fetched data
      this.setState({ tripsData: response.data, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  // Lifecycle method to get email from sessionStorage and call API
  componentDidMount() {
    const email = sessionStorage.getItem('mail'); // Retrieve email from sessionStorage
    if (email) {
      this.setState({ email }, () => {
        // Call the API with the email address
        this.fetchTrips(email);
      });
    } else {
      this.setState({ loading: false, error: 'No email found in sessionStorage' });
    }
  }

  render() {
    const { loading, tripsData, error } = this.state;

    return (
      <BaseLayout>
        <div onMouseMove={this.HandleMove} style={{ padding: '10px' }}>
          <div className='admin_cards_wrapper col d-flex justify-content-center'>
          <p className='card-landing'>Welcome to Vialto Partners downstream business travel compliance technology.
            To complete travel questionnaire, use the full website link previously provided.
            Please contact your Vialto Partners engagement team with any questions.
            For more information on Vialto Partners travel compliance solutions click <a href="https://vialto.com/" target="_blank" style={{ display: 'contents', color: '#0A838A' }}>here</a></p>
          </div>
          {/* Display the cards if data is available */}
          {!loading && !error && tripsData.length > 0 && (
            <div className='admin_cards_wrapper col d-flex justify-content-center'>
              {tripsData.map((trip, index) => (
                <div key={index} className='card'>
                  <div className='card-body'>
                    {/* <h5 className='card-title'>Trip {index + 1}</h5> */}
                    <h5 className='card-title'>{trip.recordLocator}</h5>
                    <p className='card-text'><strong>Trip ID:</strong> {trip.tripId}</p>
                    {/* <p className='card-text'><strong>Record Locator:</strong> {trip.recordLocator}</p> */}
                    <p className='card-text'><strong>Home Office:</strong> {trip.homeCountry}</p>
                    <p className='card-text'><strong>From Country:</strong> {trip.from}</p>
                    <p className='card-text'><strong>To Country:</strong> {trip.to}</p>
                    <p className='card-text'><strong>Departure Date:</strong> {new Date(trip.departureDate).toLocaleDateString()}</p>
                    <p className='card-text'><strong>Obligation:</strong> {trip.service}</p>
                    <a href={trip.link} target="_self" rel="noopener noreferrer" className='btn btn-primary'>Open Link</a>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Show a message if no trips were found */}
          {!loading && !error && tripsData.length === 0 && (
            <p>No trips found for the given email address.</p>
          )}
        </div>
      </BaseLayout>
    );
  }
}

export default MainPage;
