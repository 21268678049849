import React, { useMemo } from 'react';
import moment from 'moment';
import { Link, useLocation,useHistory } from 'react-router-dom';

export default function AdminConfig() {   
  const orkesUrl = process.env.REACT_APP_ORKES_URL; // Get the URL from the environment variable
  const history = useHistory();
  const navigate = (tripId) =>{
    sessionStorage.setItem('isOpen',true)
    history.push({
      pathname: '/AdminConsole',
      state: tripId,
      isOpen: sessionStorage.getItem('isOpen')
    });
  }
  var date = new Date();
  let assignZeroMonth = '';
  let assignZeroDate = '';
  if (date.getMonth() + 1 < 10) {
    assignZeroMonth += 0;
  }
  if (date.getDate() < 10) {
    assignZeroDate += 0;
  }
  var current_date =
    date.getFullYear() +
    '-' +
    assignZeroMonth +
    (date.getMonth() + 1) +
    '-' +
    assignZeroDate +
    date.getDate();

  let assignZero = '';
  let assignMonthZero = ''
  var fromdate = new Date();

  fromdate.setDate(fromdate.getDate() - 3);

  var yesterday = fromdate.getDate();
  var previousmonth = fromdate.getMonth() + 1
  if(previousmonth < 10){
    assignMonthZero += 0;
  }

  if (yesterday < 10) {
    assignZero += 0;
  }
  var from_date =
    fromdate.getFullYear() +
    '-' +
    assignMonthZero +
    (fromdate.getMonth() + 1)  +
    '-' +
    assignZero +
    yesterday;

  const columns = useMemo(
    () => [
      {
        Header: 'TripId',
        accessor: 'tripid',
      },
      {
        Header:'Record Locator',
        accessor:'recordlocator'
      },
      {
        Header: 'Trip Created',
        accessor: d => {
          return  moment(d.tripcreatedon)
            .local()
            .format("YYYY-MM-DD")
        },
      },    
      {
        Header: 'Updated Date',    
        accessor:d => {
          return moment(d.updated)
            .local()
            .format("YYYY-MM-DD THH:mm")
        },
      },  
      {
        Header: 'Trip Canceled',
        accessor: d => {
          return d.tripcancelledon !== null ? moment(d.tripcancelledon)
            .local()
            .format("YYYY-MM-DD") : ''
        },
        id:'tripcancelledon'
      },        
      {
        Header: 'First Name',
        accessor: 'firstname'
      },
      {
        Header:'Last Name',
        accessor:'lastname'
      },
      {
        Header:'Primary Nationality',
        accessor:'primarynationalitycountry'
      },
      {
        Header: 'Secondary Nationality',
        accessor: 'secondarynationalitycountry'
      },
      {
        Header: 'Email Address',
        accessor:d => {
          return d.emailaddress !=null ? d.emailaddress.replace("@", " @") : ''
        },
      },
      {
        Header: 'Home Office',
        accessor: 'homeofficelocation',
      },      
      {
        Header: 'From',
        accessor:'fromlocationcode'
      },
      {
        Header: 'To',
        accessor:'tolocationcode'
      },      
      {
        Header:'Departure Date',
        accessor:d => {
          return moment(d.fromlocaldate)
            .local()
            .format("YYYY-MM-DD")
        },
      },
      {
        Header:'Return Date',
        accessor:d => {
          return moment(d.returndate)
            .local()
            .format("YYYY-MM-DD")
        },
      },
      {
        Header: 'Service',
        accessor: 'service',
      },
      {
        Header: 'Advice',
        accessor: 'advice',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: props => {
          let isShowLink = true
          //if(props.cell.row.original.status === 'HRIS' || props.cell.row.original.pwdstatus === 'HRIS' || props.cell.row.original.pwdstatus === 'ReportLateTrip' || props.cell.row.original.pwdstatus === 'Stop on SalaryThreshold' || props.cell.row.original.pwdstatus === 'Reporting' || props.cell.row.original.pwdstatus === null){
          //  isShowLink = false
          //}
          //return props.value === 'Registration required' && isShowLink !== false ? <span to="/ManualSubmission" className='link_navigate'  onClick={() => navigate(props.cell.row.original)}> {props.value}</span> : <span>{props.value}</span>
          return isShowLink !== false ? <span to="/ManualSubmission" className='link_navigate'  onClick={() => navigate(props.cell.row.original)}> {props.value}</span> : <span>{props.value}</span>
        }
      },
      {
        Header:'Custom Data',
        accessor:d=>{return Object.entries(d.customdata)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join(', ');
        },
        id:'customdata'
      },
      {
        Header: 'Support Link',
        accessor: 'correlationid',
        Cell: (props) => {
          return props.value !== '' && props.value !== null ? (
            <span
              to={props.value}
              className="link_navigate"
              onClick={() =>
                window.open(`${orkesUrl}/execution/${props.value}`, '_blank')
              }
            >
              Orkes
            </span>
          ) : (
            <span>None</span>
          );
        },
      },
      {
        Header: 'Status GSheet',
        accessor: 'statusgsheet',
        Cell: props => {
          return (props.value !== "" && props.value !== null) ? <span to={props.value} className='link_navigate'  onClick={() => window.open("https://docs.google.com/spreadsheets/d/" + props.value, '_blank')}>Link</span> : <span>None</span>
        }
      },
    ],
    []
  );
  
  const perPageItems = [
    {
      value: 10,
      title: 10,
    },
    {
      value: 25,
      title: 25,
    },
    {
      value: 50,
      title: 50,
    },
    {
      value: 100,
      title: 100,
    }
  ];

  const HideColumns = [   
    'primarynationalitycountry',    
    'customdata',
    'tripcancelledon',
    'secondarynationalitycountry'
];


return { columns, perPageItems, HideColumns, current_date, from_date};
}
